import {useEffect, useRef} from "react";

// runs effect on change but not on initial mount
export const useMountedEffect = (effect: () => void, dependencies: any[] = []) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (isMounted.current) {
            effect();
        } else {
            isMounted.current = true;
        }
    }, dependencies);
};